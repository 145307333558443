<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>اضافة تصنيف جديدة </CCardHeader>
        <CCardBody>
          <CForm>
            <CRow class="mt-3">
              <CCol sm="12" md="9">
                <CInput
                  invalid
                  description=""
                  label="الاسم العربي"
                  horizontal
                  autocomplete="name"
                  v-model.trim="form.ar.name"
                  invalid-feedback="يوجد خطأ في هذا الحقل"
                  :is-valid="!$v.form.ar.name.$anyError"
                />
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol sm="12" md="9">
                <CInput
                  invalid
                  description=""
                  label="الاسم الانجليزي"
                  horizontal
                  autocomplete="name"
                  v-model.trim="form.en.name"
                  invalid-feedback="يوجد خطأ في هذا الحقل"
                  :is-valid="!$v.form.en.name.$anyError"
                />
              </CCol>
            </CRow>

            <CRow class="mt-5">
              <CCol sm="12" md="9">
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3">الدولة</label>
                  <CCol sm="9">
                    <v-select
                      :options="countries"
                      v-model="form.country_id"
                      :reduce="(country) => country.id"
                      dir="rtl"
                      label="name"
                      placeholder="اختار الدولة"
                    >
                      <template> عذراً هذه الدولة غير موجود </template>
                      <template v-if="$v.form.country_id.$anyError" #footer>
                        <div style="opacity: 0.8" class="text-danger mt-2">
                          يجب عليك اختيار الدولة
                        </div>
                      </template>
                    </v-select>
                  </CCol>
                </div>
              </CCol>
            </CRow>

            <CRow class="mt-5">
              <CCol sm="12" md="9">
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> حالة المحافظة </label>
                  <CCol sm="9">
                    <div role="group" class="form-check form-check-inline">
                      <label>
                        <input
                          type="radio"
                          name="state_id"
                          class="form-check-input"
                          value="1"
                          v-model="form.status"
                        />
                        نشط
                      </label>
                    </div>

                    <div role="group" class="form-check form-check-inline">
                      <label>
                        <input
                          type="radio"
                          name="state_id"
                          class="form-check-input"
                          value="0"
                          v-model="form.status"
                        />
                        متوقف
                      </label>
                    </div>
                  </CCol>
                </div>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
        <CCardFooter>
          <CButton
            :disabled="loading"
            @click="submit"
            type="button"
            color="success"
          >
            <CSpinner v-if="loading" color="white" size="sm" />
            <span v-else> <CIcon name="cil-save" /> حفظ </span>
          </CButton>
        </CCardFooter>
        <CCardFooter v-if="formErrors">
          <CAlert color="danger">
            <CListGroup flush>
              <CListGroupItem v-for="(err, index) in formErrors" :key="index">
                {{ err[0] }}
              </CListGroupItem>
            </CListGroup>
          </CAlert>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
export default {
  name: "Create",
  data() {
    return {
      loading: false,
      countries: [],
      formErrors: null,
      form: {
        ar: {
          name: "",
        },
        en: {
          name: "",
        },
        status: "1",
        country_id: "",
      },
    };
  },
  validations: {
    form: {
      ar: {
        name: { required, min: minLength(2), max: maxLength(200) },
      },
      en: {
        name: { required, min: minLength(2), max: maxLength(200) },
      },
      status: { required },
      country_id: { required, numeric },
    },
  },
  created() {
    this.fetchCountries();
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return true;
      }
      this.loading = true;

      this.$http
        .post(`/states`, this.form)
        .then((res) => {
          // handle success
          if (res.data.status == 202) {
            this.$router.push({ name: "States" });
          }
          this.loading = false;
        })
        .catch((error) => {
          // handle error
          this.loading = false;
          if ((error.response.data.status == 422)) {
            this.formErrors = error.response.data.error.errors;
          }
        });
    },
    fetchCountries() {
      this.toggleLoadSpinner();
      this.$http
        .get(`/general/countries`)
        .then((res) => {
          // handle success
          this.countries = res.data.data;
          this.toggleLoadSpinner();
        })
        .catch((error) => {
          //
          this.toggleLoadSpinner();
        });
    },
    toggleLoadSpinner() {
      this.$store.commit("toggleLoadSpinner");
    },
  },
};
</script>
